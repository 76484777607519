import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import http, { wrapButtonToggle } from '~/http'
import Button from '~/components/Button'
import SectionSearchResult from '~/components/SectionSearchResult'
import ErrorMessage from '~/components/ErrorMessage'
import { terseDate, durationToMoment } from '~/helpers/dateAndTime'
import { useWeek, useTimeSlot } from '~/refdata'
import useShowLoader from '../Loader'

// --vars
import {
  ages,
  defaultAge,
  fromValue
} from '~/constants/course'
import SearchResultCard from '../shared/SearchResultCard'

// --components
export function Course (props) {
  const {
    course,
    students,
    cart,
    goToAddStudent,
    preSelected = null,
    closeCards
  } = props

  const [globalError, setGlobalError] = useState(null)
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false)
  const [selectedSection, setSelectedSection] = useState(preSelected)

  useEffect(() => {
    if (closeCards) {
      setSelectedSection(null)
    }
  }, [closeCards])

  const showLocationWarning = selectedSection != null &&
    cart.reservationsByType.SQ.length > 0 &&
    selectedSection.campus != null &&
    cart.sqCampuses[selectedSection.campus] == null

  return (
    <SearchResultCard
      htmlId={`c${course.id}`}
      description={course.description}
      title={course.courseName}
      warningMessage={showLocationWarning ? 'Warning: This class is located at a different campus from other classes in your cart' : null}
      errorMessage={globalError}
      {...{ setSelectedSection, selectedSection }}
    >
      {selectedSection == null ? (
        <>
          {course.sections.map(section => (<SectionSearchResult {...{ section, setSelectedSection, course }} key={section.id} />))}
        </>
      ) : (
        <SelectedSection {...{
          cart,
          selectedSection,
          showLocationWarning,
          students,
          course,
          goToAddStudent,
          setRemoveButtonDisabled,
          removeButtonDisabled,
          setGlobalError,
          parentProps: props,
          addButtonDisabled,
          setAddButtonDisabled
        }}
        />
      )}
    </SearchResultCard>
  )
}

function SelectedSection ({
  selectedSection,
  showLocationWarning,
  students,
  course,
  goToAddStudent,
  setRemoveButtonDisabled,
  removeButtonDisabled,
  setGlobalError,
  parentProps,
  addButtonDisabled,
  setAddButtonDisabled,
  cart
}) {
  const enrollable = selectedSection.notEnrollableReason == null
  const [fetchedSection, setFetchedSection] = useState(null)
  const [prices, setPrices] = useState({ tuitionPrice: 0, suppliesPrice: 0, bookPrice: 0, totalPrice: 0 })

  const showLoader = useShowLoader()

  useEffect(() => {
    if (fetchedSection != null) {
      const { tuitionPrice, suppliesPrice, bookPrice } = fetchedSection
      setPrices({ tuitionPrice, suppliesPrice, bookPrice, totalPrice: tuitionPrice + suppliesPrice + bookPrice })
    }
  }, [fetchedSection])

  useEffect(() => {
    setGlobalError(null)
    if (selectedSection != null) {
      fetchSection(setFetchedSection, setGlobalError, selectedSection.id)
    } else {
      setFetchedSection(null)
    }
  }, [selectedSection, cart.reservationsByType.SQ])

  const week = useWeek(selectedSection.weekId)
  const timeSlot = useTimeSlot(selectedSection.timeSlotId)

  const { capacityDisplay, capacityClassName, isFull } = fetchedSection != null
    ? {
      capacityDisplay: `${fetchedSection.availableCapacity} seat${fetchedSection.availableCapacity > 1 ? 's' : ''} open`,
      capacityClassName: fetchedSection.availableCapacity <= 0 ? 'full' : fetchedSection.availableCapacity <= 3 ? 'near-full' : 'not-full',
      isFull: fetchedSection.availableCapacity <= 0
    }
    : {
      capacityDisplay: '0 seats open',
      capacityClassName: '',
      isFull: false
    }
  const sectionInCart = !!cart.classesByType.SQ.find(x => x.section.id === selectedSection.id)
  const assignedStudents = students
    .filter(student => cart.reservations.some(res => res.section.id === selectedSection.id && res.studentPublicId === student.publicId))

  const cartContentsBySection = cart.reservationsByType.SQ.reduce((acc, r) => {
    acc[`${r.section.id} ${r.studentPublicId}`] = r
    return acc
  }, {})
  const cartContentsByCourse = cart.reservationsByType.SQ.reduce((acc, r) => {
    acc[`${r.courseId} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const studentCourseDuplicates = (selectedSectionId) =>
    students.filter((student) =>
      cartContentsByCourse[`${course.id} ${student.publicId}`] != null &&
      cartContentsBySection[`${selectedSectionId} ${student.publicId}`] == null
    )

  return (
    <>
      {studentCourseDuplicates(selectedSection.id).map((student) =>
        <ErrorMessage key={`dup-${selectedSection.id}-${student.publicId}`} warning message={`Warning: Your cart already contains an enrollment in this class for ${student.firstName} ${student.lastName} `} />
      )}
      <div className='item row course-section-data'>
        <span>#{selectedSection.courseSectionNumber}</span>
        <span className={capacityClassName}>{capacityDisplay}</span>
      </div>
      <div className='item row selected-section'>
        <span>
          <div>Tuition</div>
          <div>{formatCurrency(prices.tuitionPrice)}</div>
        </span>
        <span>
          <div>Supplies</div>
          <div>{formatCurrency(prices.suppliesPrice)}</div>
        </span>
        <span>
          <div>Book</div>
          <div>{formatCurrency(prices.bookPrice)}</div>
        </span>
      </div>
      <div className='item row selected-section total-price'>
        <span>Total Price: {formatCurrency(prices.totalPrice)}</span>
      </div>
      <div className='item row selected-section'>
        <span className='info'>{`${week.name}, ${terseDate(week.startDate)}-${terseDate(week.endDate)}`}</span>
        <span className='info'>{`${durationToMoment(timeSlot.startTime).format('h:mm')}-${durationToMoment(timeSlot.endTime).format('h:mm A')}`}</span>
      </div>
      <div className='item row selected-section'>
        <span className={`info ${showLocationWarning ? 'location-warning' : ''}`}>
          {`${selectedSection.campus}${selectedSection.campus === 'Off-Site' ? '' : '  Campus'}`}
        </span>
        <span className='info'>{fromValue(selectedSection.ageGroup, ages, defaultAge).label}</span>
      </div>

      {!enrollable && (
        <>
          <div className='item row no-student'>
            {selectedSection.notEnrollableReason}
          </div>
          <div className='item row no-student'>
              Please give us a call at 405-717-4900 between the hours of 8:00 AM and 4:30 PM and we'll assist you.
          </div>
        </>
      )}

      {enrollable && (
        <>
          <div className='item row no-student'>
            {(() => {
              if (isFull) {
                return <span>Full</span>
              } else if (!sectionInCart) {
                return (
                  <Button
                    label='Add to Cart'
                    unelevated
                    dense
                    disabled={addButtonDisabled}
                    onClick={
                      wrapButtonToggle(
                        setAddButtonDisabled,
                        async () => {
                          showLoader(true)
                          await addToCart(parentProps, selectedSection)
                          showLoader(false)
                        })
                    }
                  />
                )
              } else if (sectionInCart) {
                return (
                  <>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Button
                        icon='shopping_cart'
                        label='Checkout'
                        outlined
                        dense
                        onClick={() => {
                          window.scrollTo(0, 0)
                          parentProps.history.push('/cart')
                        }}
                      />
                      {assignedStudents.length < 1 &&
                        <Button
                          label='Remove'
                          outlined
                          dense
                          disabled={removeButtonDisabled}
                          onClick={
                            wrapButtonToggle(
                              setRemoveButtonDisabled,
                              async () => {
                                showLoader(true)
                                await removeFromCart(parentProps, selectedSection)
                                showLoader(false)
                              })
                          }
                        />}
                    </div>
                  </>)
              }
            })()}
          </div>
          <div className='item row no-student'>
            {assignedStudents.length > 0 &&
              <p>Cannot remove class from cart while students are assigned to it. Go to the checkout page and remove all students from this class before removing it from your cart.</p>}
          </div>
        </>
      )}
    </>
  )
}

// --functions
async function fetchSection (setFetchedSection, setGlobalError, id) {
  const response = await http(setGlobalError, http => http.get(`/api/SelfService/CourseSection/${id}`))
  setFetchedSection(response.data)
}

async function removeFromCart (props, section) {
  props.dispatch({
    type: 'REMOVE_CLASS',
    data: {
      cart: props.cart.publicId,
      sectionId: section.id
    }
  })
}

async function addToCart (props, section) {
  props.dispatch({
    type: 'ADD_CLASS',
    data: {
      cart: props.cart.publicId,
      course: props.course,
      section: section,
      type: 'SQ'
    }
  })
}

function formatCurrency (amount) {
  return `$ ${parseFloat(amount).toFixed(2)}`
}
// --mappings
export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(Course)
