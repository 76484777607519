import React, { useEffect, useContext } from 'react'
import { HeaderContext, defaultPageTitle } from '../App'
import Button from '~/components/Button'

import '../scss/SqLanding.scss'

export default function WelcomePage (props) {
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: defaultPageTitle, isSqView: true })
  }, [])

  return (
    <div className='page welcome'>

      <div className='page-header'>
        <div className='contents'>
          <div className='title'>
            <div>Summer Quest Enrollment Page</div>
          </div>

          <div className='paragraph'>
            Summer Quest programs offer students ages 11 to 14 the opportunity
            to take fun educational classes during the month of June.
          </div>

          <div className='paragraph'>
            This year, the Summer Quest program is offered in four weekly sessions from June 3rd to June 27th
            from 9&nbsp;a.m. to 3&nbsp;p.m. On-campus classes take place all day, Tuesday through Friday, with
            the  exception of June 16-20. Due to the observed Juneteenth holiday on June 19th, that week classes
            will run Monday through Friday.
          </div>

          <div className='paragraph'>
            Tuition is $95 each week, and supplies are $25, due at the time of enrollment. To cancel, please let
            us know three business days before the class begins, and we will be happy to provide a refund for the
            tuition. The $25 supply cost is nonrefundable. If you drop an enrollment and wish to enroll in another
            class, we can transfer the cost (including supplies) to the new enrollment. Please note, if you cancel
            an enrollment within three business days of the first day of the class, no refund will be provided.
          </div>

          <div className='paragraph'>
            For any other questions related to Summer Quest enrollment, please call us at 405-717-4900.
          </div>

          <div className='button-container'>
            <Button
              unelevated
              label='Enroll a student'
              onClick={() => {
                window.scrollTo(0, 0)
                props.history.push('/students')
              }}
            />
            <Button
              outlined
              label='View Classes'
              onClick={() => {
                window.scrollTo(0, 0)
                props.history.push('/sq-courses')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
